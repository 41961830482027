import browser from 'imports/browser-detect'
import Cookies from 'imports/js-cookie'
import siteCommon from 'site/site-common'

let site = {
    browser: browser(),

    init: function () {
        // Add browser name as a class
        $('body').addClass("browser_" + site.browser.name);

        // Remove jQuery Mobile classes
        $('.ui-link').removeClass('ui-link');

        // Disable image dragging
        $('img').on('dragstart', function (event) {
            event.preventDefault();
        });

        // Add device class
        if (site.browser.mobile) {
            $('body').addClass("device-mobile");
        } else {
            $('body').addClass("device-notmobile");
        }

        site.languageDetect();

        window.addEventListener('resize', site.resize);
        site.resize();

        // Language hook: observe .gtranslate_wrapper for dynamic language links
        const observer = new MutationObserver(function (mutations) {
            for (let mutation of mutations) {
                if (mutation.type === 'childList' && mutation.addedNodes.length > 0) {
                    const wrapper = document.querySelector('.gtranslate_wrapper');
                    if (wrapper) {
                        wrapper.querySelectorAll('a').forEach(anchor => {
                            anchor.addEventListener('click', function () {
                                const current_language = this.getAttribute('data-gt-lang');
                                if (current_language) {
                                    document.getElementById("current-language-lbl").textContent = gt_get_name(current_language);
                                }
                            });
                        });
                    }
                }
            }
        });

        const gtranslateWrapper = document.querySelector('.gtranslate_wrapper');
        if (gtranslateWrapper) {
            observer.observe(gtranslateWrapper, { childList: true, subtree: true });
        }
    },

    languageDetect() {
        const params = new URLSearchParams(window.location.search);
        if (params.has('l')) {
            // Forced language via query string
            site.setCookieAsync('lang', site_lang, { expires: 365 * 100 }).then(() => {});
        } else {
            let lang = Cookies.get('lang');
            if (!lang) {
                const preferredLanguage = navigator.language || navigator.userLanguage;
                lang = preferredLanguage.split('-')[0];
            }

            if (site_lang !== lang && site_languages.includes(site_lang)) {
                site.switchUrlToLanguage(lang);
            }
        }
    },

    setCookieAsync(name, value, options) {
        return new Promise(resolve => {
            Cookies.set(name, value, options);
            resolve();
        });
    },

    switchUrlToLanguage(lang) {
        const uri = (site_uri === '__home__') ? '' : site_uri;
        Cookies.set('lang', lang, { expires: 365 * 100 });

        site.setCookieAsync('lang', lang, { expires: 365 * 100 }).then(() => {
            if (lang === 'en') {
                window.location = "/" + uri;
            } else {
                window.location = '/' + lang + '/' + uri;
            }
        });
    },

    resize() {
        // Set CSS variables for viewport height and offset
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);

        let ol = (window.innerWidth - 1920) / 2;
        if (ol < 0) ol = 0;
        document.documentElement.style.setProperty('--offsetLeft', `${ol}px`);

        site.siteOrientationChange();
    },

    siteOrientationChange() {
        // Placeholder for orientation handling
        // Currently not used
    }
};

export default site;
